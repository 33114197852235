@import "size";

/* COLORS */
@import "color";

footer {
    z-index: 4;
    position: absolute;
    bottom:0;
    margin: 0;
    padding: 0 0 0 0;
    display: flex;
    font-family: Inconsolata, Arial, Helvetica, sans-serif;
    color:$red-2;
    font-weight: 500;
    font-style: normal;
    //height: 100px;
    width: 100%;
    h4 {
        font-size: 1rem;
        font-family: decoy, serif;
        font-weight: 300;
        font-style: normal;
    }
    .trademark {
      padding: 0 0 10px 10px;
      font-size: 0.7rem;
      margin-top: 10px;
      display: block;
      @media screen and (max-width:$mobilescr) {
          padding: 0 0 5px 10px;
      }
    }
    .icons {
        margin: 0px 10px 0px auto;
        padding: 0px;
        height: 100%;
        display: flex;
        .eq-animation_start {
            animation-play-state: running;
        }
        .eq-animation_stop {
            animation-play-state: paused;
        }
        @media screen and (max-width:$mobilescr) {
            margin: 0px 10px 5px auto;

        }
        svg {
          height: 20px;
          margin-top:23px;
          @media screen and (max-width:$mobilescr) {
              margin-top:25px;
          }
        }
        #equalizer {
            margin-right: -3px;
            fill: $red-2;

            @media screen and (min-width:$largescr) {
                //width: 45px;
            }

            @media screen and (max-width:$middlescr) {
                //width: 30px;
            }

            @media screen and (max-width:$mobilescr) {
                //width: 25px;
                margin-right:-10px;

            }

            #bar1 {
              animation: bar1 1.2s infinite linear;
              /* animation-play-state: paused; */
            }

            #bar2 {
              animation: bar2 0.8s infinite linear;
              /* animation-play-state: paused; */
            }

            #bar3 {
              animation: bar3 1s infinite linear;
              /* animation-play-state: paused; */
            }

            #bar4 {
              animation: bar4 0.7s infinite linear;
              /* animation-play-state: paused; */
            }

            @keyframes bar1 {
              0% {
                height: 2px;
              }
              50% {
                height: 7px;
              }
              100% {
                height: 2px;
              }
            }

            @keyframes bar2 {
              0% {
                height: 5px;
              }
              40% {
                height: 1px;
              }
              80% {
                height: 7px;
              }
              100% {
                height: 5px;
              }
            }

            @keyframes bar3 {
              0% {
                height: 7px;
              }
              50% {
                height: 0;
              }
              100% {
                height: 7px;
              }
            }

            @keyframes bar4 {
              0% {
                height: 2px;
              }
              50% {
                height: 7px;
              }
              100% {
                height: 2px;
              }
            }
        }
        #emoji{
            fill: $red-2;
            @media screen and (min-width:$largescr) {
                //width: 150px;
            }

            @media screen and (max-width:$middlescr) {
                //width: 100px;
            }

            @media screen and (max-width:$mobilescr) {
                width: 100px;
                margin-right:-10px
            }
        }
    }
}
