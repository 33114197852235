@import "size";

/* COLORS */
@import "color";

header {
    z-index: 100;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: decoy, serif;
    font-weight: 300;
    font-style: normal;
    color:$red-2;
    display: flex;
    font-family: Inconsolata, Arial, Helvetica, sans-serif;
    @media screen and (min-width:$largescr) {
        display: flex;
    }

    @media screen and (max-width:$middlescr) {

    }

    @media screen and (max-width:$mobilescr) {

    }
    div.logo {
      display: flex;
      flex-grow: 3;
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                text-decoration: none;
                h1, h2 {
                    font-size: 1.25rem;
                }
                h1.logo {
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
    div.about {
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        flex-flow: column wrap;
        justify-content: right;
        margin:-5px 0px 0px auto;
        ul {
            list-style: none;
            margin: 0px 10px 0px auto;
            padding: 0;
            li {
                text-decoration: none;
                display: flex;
                flex-shrink:1;
                justify-content: right;
                h1, h2 {

                    font-size: 1.5rem;
                    line-height: 0px;
                }
                h2.menu-button {
                    text-align: right;
                    cursor: pointer;
                }
            }
        }
        div.about-border {
          border: 1px solid #ffffff;
          border-right-color: #1a1a1a;
          border-bottom-color: #1a1a1a;
        }
        div.about-wrapper {
          margin:40px 40px;
          background-color: $red-2;
          padding: 2px;
          @media screen and (max-width:$mobilescr) {
            position: absolute;
            left: 0;
          }
        }
        div.about-links {
            display: flex;
            flex-shrink:1;
            a {
              color:$blue;
            }
            //background-color: rgb(255, 255, 255);

            @media screen and (min-width:$largescr) {
                //width: 50vw;
            }

            @media screen and (max-width:$middlescr) {
                width: 100%;
            }

            @media screen and (max-width:$mobilescr) {
                width: 100%;
            }
            ul {
                list-style: none;
                margin: 0px 0px 0px auto;
                padding: 10px;
                li {
                    margin-left: 1em;
                    text-decoration: none;
                    float: left;
                    h1, h2 {
                        font-size: 1.25rem;
                        line-height: 0px;
                        margin-top: 10px;
                    }
                }
            }
        }
        div.about-desc {
            color:$green;
            padding-top: 5vh;
            padding: 10px 20px;
            font-weight: 400;

            @media screen and (min-width:$largescr) {
                width: 50vw;
                p {
                    padding: 35px;
                }
            }

            @media screen and (max-width:$middlescr) {
                width: 100%;
                p {
                    padding: 35px;
                }
            }

            @media screen and (max-width:$mobilescr) {
                width: 100%;
                p {
                    padding: 35px;
                }
            }
        }
    }
}
