@import "size";

/* COLORS */
@import "color";

/*FONTS */
$sans       : Inconsolata, Arial, Helvetica, sans-serif;

/*Random BG */
$bgKey: random(3);
$list: $yellow, $yellow, $yellow;
$nth: nth($list, $bgKey);

html {
    margin: 0;
    padding: 0;
    font-family: $sans, sans-serif;
    font-weight: 400 !important;
    font-style: normal !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    background-color: $pink-2 !important;
    min-height: -webkit-fill-available;

    @media screen and (min-width:$largescr) {
        font-size: 16px !important;
    }

    @media screen and (max-width:$middlescr) {
        font-size: 16px !important;
    }

    @media screen and (max-width:$mobilescr) {
        font-size: 16px !important;
    }
}

body {
    margin: 0;
    padding: 0;
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    min-width: 100vw;
    overflow: hidden;
    height: 100%;
    position: fixed;
}

.App {
  overflow: clip;
}

header {
    z-index: 4;
    position: relative;
}

#content {
    z-index: 4;
    position: relative;
    margin: 0px;
    width: 100vw;
    min-height: calc(100vh - 50px);
    display: table;
}

.content-inner {
    display: table-cell;
    vertical-align: middle;

    svg#smoke-logo {
      fill:$red-2;
      width: 80vw;
      margin: 0 auto;
      display: block;
      @media screen and (max-width:$middlescr) {
          width: 100vw;
      }

      @media screen and (max-width:$mobilescr) {
          width: 90vw;
      }
    }

    img#ig-link {
      width:50px;
      margin: 0 auto;
      display: block;
    }
}

footer {
    position: relative;
}

#smoke-logobase {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    h2 {
      text-align: center;
      font-size: 2rem;
      color: white;
      line-height: 1rem;
      display: block;
      position: absolute;
      top:18vh;
      left: 0;
      right: 0;
      bottom:0;
      margin:auto;
    }

    svg#smoke-logo {
        position: absolute;
        //top: 60vh;
        left: 0;
        right: 0;
        bottom: 10px;
        margin: auto;
        adding: 0 0 0 50px;
        fill:$red-2;
        @media screen and (min-width:$largescr) {
            width: 30vw;
            //height: 90vh;
        }

        @media screen and (max-width:$middlescr) {
            width: 30vw;
            //height: 90vh;
        }

        @media screen and (max-width:$mobilescr) {
            width: 30vw;
            bottom:5px;
            //height: 100vh;
        }
    }


}

#smoke {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 100%;
    mask-image: linear-gradient(to bottom, transparent 0%, black 200px);
    //box-shadow: 0 0 400px $pink-2 inset;
}

#smoke-cover {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    //background-image: radial-gradient(rgb(255, 220, 236) 15%, transparent 26%),
    //radial-gradient(rgb(255, 220, 236) 15%, transparent 26%);
    background-position: 0 0, 2px 2px;
    background-size: 2px 2px;
    height: 100%;
    width: 100%;
}

.Schedule {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: block;
    position: absolute;
    top: 14vh;
    left: -32vh;
    width: 100vw;
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
    text-align: center;
    transform-origin: top right;
    color:$red-2;
    text-transform: lowercase;
    mask-image: linear-gradient(to bottom, black calc(100% - 200px), transparent 100%);


    /* MASTER TEXT STYLES */
    .schedule-time {
        font-weight: 700;
        display: block;
        font-family: $sans;
    }
    .schedule-small {
      font-style: italic;
      vertical-align: super;
    }
    .time-zone {
      text-transform: uppercase;
    }
    .schedule-day {
        display:block;
        //border-top: 1px dashed $yellow;
        width: auto;
        padding: 0.5rem 0;
        margin: 0.5rem 2rem 0;
        font-family: $sans;
        text-transform: lowercase;
        font-weight: bold;
        font-size: 1rem;
        margin-top: 0.5rem;
        &::before {
          content:'♪';
          display: block;
          font-size:0.8rem;
          margin-bottom: 0.8rem;
        }


    }
    ul {
      mask-image: linear-gradient(to bottom, transparent 0%, black 20px);
    }

    #wavy {
      fill:$red-2;
      font-family: $sans;
      font-size: 1rem;
      display: block;
      text {
        text-anchor: middle;
        //dominant-baseline: middle;
      }
    }

    .week {
      font-weight: 700;
    }
    .schedule-title {
        font-weight: 700;
        font-family: decoy,serif;
        font-style: italic;
        text-transform: uppercase;
        display: block;
        margin-bottom:2rem;
        animation: play1 1.2s infinite linear;
    }

    @keyframes play1 {
      0% {
        transform: translate(0,0)
      }
      50% {
        transform: translate(9px, -8px)
      }
      100% {
        transform: translate(12px, -12px)
      }
    }

    .index:last-child {
        margin-bottom: 30vh;
    }

    /* SCREEN TEXT STYLES */

    @media screen and (min-width:$extralargescr) {
        transform: translate(0vw, calc(0vh - 25vh)) skew(60deg, -30deg) scaleY(calc(.66667));
        transition: transform 1s;
        .schedule-small {
            font-size: 0.7rem;
        }
        .schedule-time {
            font-size: 1rem;
            line-height: 1rem;
        }
        .schedule-title {
            margin-left: 10px;
            font-size: 1.5rem;
            line-height: 1.5rem;
        }


        h2 {
            margin: 0px 0px 0px 0px;
            padding: 0px 0px 0px 0px;
            width: 100%;
            font-weight: 300;
            font-style: normal;
            font-size: 2rem;
        }
        ul {
            margin: 0px 0px 0px 0px;
            padding: 0px 0px 0px 0px;
            display:block;
            width: 100%;
            max-height: 100vh;
            overflow: scroll;
            list-style: none;
            font-size: 1.5rem;
            li {
                text-decoration: none;
                ul {
                    width: 100%;
                    li {
                        width: 100%;
                    }
                }
            }
            &::-webkit-scrollbar {
              display: none;
            }
        }
    }

    @media screen and (max-width:$largescr) {
        transform: translate(0vw, calc(0vh - 25vh)) skew(45deg, -23deg) scaleY(calc(.66667));
        transition: transform 1s;
        .schedule-small {
            font-size: 0.7rem;
        }
        .schedule-time {
            font-size: 1rem;
            line-height: 1rem;
        }
        .schedule-title {
            margin-left: 10px;
            font-size: 1.5rem;
            line-height: 1.5rem;
        }

        h2 {
            margin: 0px 0px 0px 0px;
            padding: 0px 0px 0px 0px;
            width: 100%;
            font-weight: 300;
            font-style: normal;
            font-size: 2rem;
        }
        ul {
            margin: 0px 0px 0px 0px;
            padding: 0px 0px 0px 0px;
            display:block;
            width: 100%;
            max-height: 100vh;
            overflow: scroll;
            list-style: none;
            font-size: 1.5rem;
            li {
                text-decoration: none;
                ul {
                    width: 100%;
                    li {
                        width: 100%;
                    }
                }
            }
            &::-webkit-scrollbar {
              display: none;
            }
        }
    }

    @media screen and (max-width:$middlescr) {
        transform: translate(0vw, calc(0vh - 25vh)) skew(30deg, -15deg) scaleY(calc(.66667));
        transition: transform 1s;
        top: 30vh;
        left: -10vw;
        .schedule-small {
            font-size: 0.5rem;
        }
        .schedule-time {
            font-size: 0.8rem;
            line-height: 0.8rem;
        }
        .schedule-title {
            margin-left: 10px;
            font-size: 1.3rem;
            line-height: 1.3rem;

        }

        h2 {
            margin: 0px 0px 0px 0px;
            padding: 0px 0px 0px 0px;
            width: 100%;
            font-weight: 300;
            font-style: normal;
            font-size: 1.5rem;
        }
        ul {
            margin: 0px 0px 0px 0px;
            padding: 0px 0px 0px 0px;
            display:block;
            width: 100%;
            max-height: 100vh;
            overflow: scroll;
            list-style: none;
            font-size: 1.3rem;
            li {
                text-decoration: none;
                ul {
                    width: 100%;
                    li {
                        width: 100%;
                    }
                }
            }
            &::-webkit-scrollbar {
              display: none;
            }
        }
    }

    @media screen and (max-width:$mobilescr) {
        transform: translate(0vw, calc(0vh - 15vh)) skew(0deg, 0deg) scaleY(calc(.66667));
        transition: transform 1s;
        top: 30vh;
        left: 0;
        text-align: left;
        max-width:100vw;
        .schedule-day {
          margin: 1rem 0.5rem;
          font-size: 1.5rem;
          &::before {
            font-size:2rem;
            margin-bottom: 0.8rem;
          }
        }
        .schedule-small {
            font-size: 1.5rem;
        }
        .schedule-time {
            font-size: 1.5rem;
            line-height: 1.5rem;
            margin-left: 0.5rem;
        }
        .schedule-title {
            margin-left: 0.5rem;
            font-size: 2.2rem;
            line-height: 2.2rem;
        }


        h2 {
            margin: 0px 0px 0px 0.5rem;
            padding: 0px 0px 0px 0px;
            width: auto;
            font-weight: 300;
            font-style: normal;
            font-size: 3.5rem;
            display: block;
        }
        ul {
            margin: 0px 0px 0px 0px;
            padding: 0px 0px 0px 0px;
            display:block;
            width: 100%;
            max-width: 100vw;
            max-height: 100vh;
            overflow: scroll;
            list-style: none;
            font-size: 1rem;
            overscroll-behavior-x: none;
            overscroll-behavior-y: none;
            li {
                text-decoration: none;
                ul {
                    width: 100%;
                    li {
                        width: 100%;
                    }
                }
            }
            &::-webkit-scrollbar {
              display: none;
            }
        }
        #wavy {
          margin:2rem 0;
          font-size: 2.2rem;
        }

    }

    /* transform: translate(0vw, calc(0vh - 25vh)) skew(60deg, -30deg) scaleY(.66667); */
    /* transform: matrix3d(1.0666688000000002,-0.77,0.00,0,0.77,1.0666688000000002,0.00,0,0,0,1,0,30,180,0,1); */
    /* -webkit-transform: matrix3d(1.0666688000000002,-0.77,0.00,0,0.77,1.0666688000000002,0.00,0,0,0,1,0,30,180,0,1); */
    -webkit-filter:drop-shadow(-5px 4px 5px $grey);
    -moz-filter:drop-shadow(-5px 4px 5px $grey);
    -ms-filter:drop-shadow(-5px 4px 5px $grey);
    filter:drop-shadow(-5px 4px 5px $grey);


}

.audio-wrap {
  display: block;
  //height: 3.5rem;
  //line-height: 3.5rem;
  width: 5rem;
  padding: 1rem 1rem;
  margin: 0 auto;
  background: none;
  object-fit: contain;
  position: relative;
  border: 2px solid $red-2;
  border-radius: 40px;
  text-transform: lowercase;
  text-align: center;
  cursor: pointer;
}

.audio-wrap:hover {
    filter: brightness(85%);
}

#content audio {
    display: none;
    margin:0 auto;
    padding: 0;

}


/*CRT EFFCETS*/

.BackgroundVideo {
  z-index: 0;
  width:100vw!important;
  height:100vh!important;
  position: relative;
  top:0!important;
  bottom:0;
  left:0!important;
  right: 0;
  margin:auto;
  background: $nth;

video {
    filter:blur(15px);
    animation: video-blur 15s infinite linear;
    opacity:0.1;
  }
  @keyframes video-blur {
    0% {
      filter: blur(15px);
    }
    50% {
      filter: blur(5px);
    }
    100% {
      filter: blur(15px);
    }
  }
}

.BackgroundVideos::after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(18, 16, 16, 0.1);
  opacity: 0;
  z-index: 2;
  pointer-events: none;
  animation: flicker 5.5s infinite;
}

.BackgroundVideos::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
  z-index: 2;
  background-size: 100% 2px, 3px 100%;
  pointer-events: none;
}

.BG-overlay {
  width:100%;
  height:100%;
  position:absolute;
  top:0;
}

/*END CRT */
