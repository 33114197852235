$yellow     : #fed402;
$yellow-2   : rgb(242, 234, 41);
$amber      : #fcb900;
$pink       : rgb(245, 172, 204);
$pink-2     : rgb(255, 220, 236);
$green      : rgb(67, 183, 122);
$green-2    : #7bdcb5;
$celadon    : rgb(216, 232, 222);
$blue       : rgb(0, 50, 207);
$light-blue : #8ed1fc;
$red        : rgb(233, 52, 52);
$red-2      : #ef3e23;
$red-3      : #f5522e;
$orange     : #ff6900;
$grey       : rgba(0,0,0,0.14);
$purple     : #9b51e0;
